/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.header {
  font-weight: 700 !important;
}

.pin-identifier {
  color: #000;
  position: absolute;
  top: 26px;
  text-align: center !important;
  width: 105%;
  font-size: 10px;
  font-weight: 600;
}

.leaflet-div-icon {
  background: rgba(255, 255, 255, 0) !important;
  border: 1px solid #6660 !important;
}
.ngx-charts text { fill: #000000; }

@media (prefers-color-scheme: dark) {
  .ngx-charts text { fill: #fff; }
}

.login-logo {
  min-height: 80px;
  width: 75%;
  background-repeat: no-repeat, repeat;
  background-position: center;
  background-size: contain;
  margin: auto;
  margin-top: constant(safe-area-inset-top);
  margin-top: env(safe-area-inset-top);
}

@media (prefers-color-scheme: dark) {
  .login-logo {
      background-image: url("assets/logo/FSP_Dark.png");
  }
}

@media (prefers-color-scheme: light) {
  .login-logo {
      background-image: url("assets/logo/FSP_Master.png");
  }
}
